
export default {
    name: 'BirthdayUser',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            userId: '',
            user: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.userId = this.$storage.get('userId') || ''
        this.getUserInfoAjax()
    },
    methods: {
        // 获取会员信息
        getUserInfoAjax() {
            if(!this.userId){
                return
            }
            this.$api.user.getUseInfo({
                id: this.userId
            }).then(response => {
                const res = response.result || {};
                this.user = res
            })
        },
        handleRight() {
            if(this.user.birthday) return
            this.$router,push({
                name: 'Profile'
            })
        }
    },
}
