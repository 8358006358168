
export default {
    name: 'MineUser',
    props: {
        lang: Object
    },
    data() {
        return {
            query: {},
            hasLogin: false,
            currency: {},
            user: {
                headImage: 'https://cdn2.selleroa.com/yfn-upload/review/1693884509488.png'
            },
            member: {
                userLevel: '',
                levelImg: []
            },
            tab: {
                list: []
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        if(this.hasLogin) {
            this.getUserInfoAjax()
            this.getMemberInfoAjax()
            this.getCouponNumAjax()
            this.getCollectNumAjax()
            this.getGiftCardAmountAjax()
            this.getWalletBalanceList()
        }
    },
    methods: {
        jumpProfile(){
            if(!this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            this.$router.push({
                name: 'Profile'
            })
        },
        // 获取用户信息
        getUserInfoAjax() {
            if(!this.user.id){
                return
            }
            this.$api.user.getUseInfo({
                id: this.user.id
            }).then(response => {
                const res = response.result || {};
                if(response.code != 'success') {
                    this.user = {}
                    this.initialSet()
                    this.$storage.remove('token')
                    return
                }
                this.user = res || {}
                this.user.oneStr = this.user.nickName ? this.user.nickName.slice(0, 1) : 'Y'
            })
        },
        // 获取会员信息
        getMemberInfoAjax() {
            this.$api.member.getMemberInfo().then(response => {
                const res = response.result || {};
                this.member = {...this.member, ...res}
            })
        },
        // 获取优惠券数量
        getCouponNumAjax() {
            this.$api.coupon.getCouponNum().then(response => {
                const res = response.result || {};
                this.tab.list[0].num = res.count || 0
                this.tab.list[0].hasDot = res.showNewCouponRemind
            })
        },
        // 获取钱包余额
        getWalletBalanceList() {
            this.$api.wallet.getWalletBalanceList().then(response => {
                const res = response.result || {};
                this.tab.list[1].num = this.$price(res.displayWalletAmount, res.symbol)
                this.tab.list[1].hasDot = res.showNewWalletRemind
            })
        },
        // 获取收藏数量
        getCollectNumAjax() {
            this.$api.user.getCollectNum().then(response => {
                const res = response.result || {};
                this.tab.list[2].num = res.result || 0
            })
        },
        // 获取礼品卡金额
        getGiftCardAmountAjax() {
            this.$api.giftCard.getGiftCardAmount().then(response => {
                const res = response.result || {};
                this.tab.list[3].num = this.$price(res.displayTotalAmount)
            })
        },
        // 设置
        handleSet() {
            this.$router.push({
                name: 'Setting'
            })
        },
        // 客服
        handleEChart() {
            // if(ECHAT) {
            //     ECHAT.customMiniChat()
            // }
        },
        // 会员
        handleMember() {
            this.$router.push({
                name: 'MemberCenter'
            })
        },
        // 跳转
        jumpLogin() {
            this.$router.push({
                name: 'Login'
            })
        },
        jumpLink(obj) {
            if(!this.hasLogin) {
                this.$router.push({
                    name: 'Login'
                })
                return
            }
            this.setRemind(obj)
            this.$router.push({
                name: obj.url
            })
        },
        handleBack() {
            this.$router.back()
        },
        // 取消获得标识
        setRemind(obj) {
            if(![1, 2].includes(obj.type)) return
            const key = ['setCancelRemindCoupon', 'setCancelRemindWallet'][obj.type - 1];
            this.$api.user[key]().then(() => {})
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login();
            this.user.id = this.$storage.get('userId');
            this.currency = this.$storage.get('currency') || {}
            this.query = this.$route.query;
            this.tab.list = [{
                title: this.$translate('Coupons'),
                icon: 'icon-coupon',
                num: 0,
                status: this.hasLogin,
                url: 'Coupon',
                type: 1,
            }, {
                title: this.$translate('Wallet'),
                icon: 'icon-wallet',
                num: `${this.currency.symbol}0.00`,
                status: this.hasLogin,
                url: 'Wallet',
                type: 2,
            }, {
                title: this.$translate('Wishlist'),
                icon: 'icon-wish',
                num: 0,
                status: this.hasLogin,
                url: 'Wish',
                type: 3,
            }, {
                title: this.$translate('Gift Card'),
                icon: 'icon-gift-card',
                num: 0,
                status: this.hasLogin,
                url: 'GiftCardBalance',
                type: 7,
            }]
            this.member.levelImg = {
                1: 'https://cdn2.selleroa.com/yfn-upload/review/1696989488480.png',
                2: 'https://cdn2.selleroa.com/yfn-upload/review/1695880979378.png',
                3: 'https://cdn2.selleroa.com/yfn-upload/review/1695880981375.png',
                4: 'https://cdn2.selleroa.com/yfn-upload/review/1695880983476.png'
            }
        }
    },
}
