import { render, staticRenderFns } from "./BirthdayUser.vue?vue&type=template&id=30e9bf84&scoped=true&"
import script from "./BirthdayUser.vue?vue&type=script&lang=js&"
export * from "./BirthdayUser.vue?vue&type=script&lang=js&"
import style0 from "./BirthdayUser.vue?vue&type=style&index=0&id=30e9bf84&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e9bf84",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
